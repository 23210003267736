/* Keyframes for animations */
@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: translateY(2vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* General styles */
.title-image, .title-section h1, .title-section p {
  opacity: 1;
}

.centered-button {
  opacity: 1;
}



.centered-button {
  margin-top: 5vh;
  padding: 1.5vh 3vw;
  font-size: max(1.5vw, 1.5vh);
  cursor: pointer;
  color: #fff;
  background: linear-gradient(90deg, #1c1b1b 0%, #2d3772 100%);
  border: none;
  border-radius: max(1.5vw, 1.5vh);
  box-shadow: 0 0.4vh 0.6vh rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: 'Poppins', sans-serif;
  letter-spacing: max(0.1vw, 0.1vh);
}

.centered-button:hover {
  background: linear-gradient(90deg, #363535 0%, #007bff 100%);
  transform: translateY(-0.2vw);
}

:root {
  --navbar-height: 80px;
}

/* Title section styles */
.title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  height: calc(100vh - var(--navbar-height));
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.title-section-text {
  width: 61.8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-section h1 {
  color: #333;
  font-size: max(3vw, 3vh);
  font-weight: 700;
  text-shadow: 0.2vh 0.2vh 0.4vh rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.title-section p {
  color: #555;
  font-size: max(2vw, 2vh);
  font-family: 'Roboto', Arial, sans-serif;
  text-shadow: 0.1vh 0.1vh 0.3vh rgba(0, 0, 0, 0.1);
}


.title-image {
  border-radius: 1vh;
  box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.1);
  max-height: 61.8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto; /* Center the image */
}

@media (max-width: 960px) {
    .title-section {
      padding: 0 4vw;
    }


  }

@media (max-width: 768px) {
  .title-section {
    flex-direction: column;
    justify-content: flex-start;
    padding: 2vh 2vw;
    overflow: hidden;
    object-fit: scale-down;
  }


  .title-image {
    max-height: 100%;
    border-radius: 1vh;
    box-shadow: 0 0.4vh 0.8vh rgba(0, 0, 0, 0.1);
    padding: 0;
    object-fit: scale-down;
  }

  .title-image-container {
    max-height: calc(50vh - var(--navbar-height)/2);
    padding: 0;
  }

  .title-section-text {
    max-height: calc(50vh - var(--navbar-height)/2);
    padding: 0;
  }



  .title-section-text p {
    font-size: max(3vw, 3vh);
  }

  .centered-button {
    font-size: max(2.5vw, 2.5vh);
    padding: 1vh 5vw;
    border-radius: max(2vw, 2vh);
  }


}