@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap');

:root {
  --color-primary: #6B7A8F;
  --color-secondary: #f5f5f5;
  --color-accent: #1D1F2F;
  --color-focus: #6D64F7;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

:root {
  --navbar-height: 5rem; /* assuming 1rem = 16px */
}

// =========================
// Global
// =========================

*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: var(--color-secondary);
}

#app {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
}

.visuallyhidden { 
  clip: rect(1px, 1px, 1px, 1px);  
  height: 1px; 
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}


// =========================
// Icons
// =========================

.icon {
  fill: var(--color-primary);
  width: 100%;
}


// =========================
// Buttons
// =========================

.btn {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.25rem; /* Increase font size */
  padding: 1rem 2rem; /* Increase padding */

  &:focus {
    outline-color: var(--color-focus);
    outline-offset: 2px;
    outline-style: solid;
    outline-width: 3px;
  }

  &:active {
    transform: translateY(1px);
  }
}


// =========================
// Slider controls
// =========================

.slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(88vh - var(--navbar-height));
  width: 100%;

  .btn {
    --size: 3rem;
    align-items: center;
    background-color: transparent;
    border: 3px solid transparent;
    border-radius: 100%;
    display: flex;
    height: var(--size);
    padding: 0;
    width: var(--size);

    &:focus {
      border-color: var(--color-focus);
      outline: none;
    }

    &--previous > * {
      transform: rotate(180deg);
    }
  }
}


// =========================
// Slider
// =========================

.slider {
  --slide-width: 38.2vw;
  --slide-height: 61.8vh;
  --slide-margin: 4vmin;

  margin: 0 auto;
  position: relative;
  width: var(--slide-width);
  height: var(--slide-height);
}

.slider__wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}


// =========================
// Slide
// =========================

.slide {
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: var(--slide-height);
  justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 0.25;
  position: relative;
  text-align: center;
  transition: 
    opacity calc(var(--base-duration) / 2) var(--base-ease),
    transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-width);
  z-index: 1;
  
  &--previous,
  &--next {    
    &:hover {
      opacity: 0.5;
    }
  }
  
  &--previous {
    cursor: w-resize;
    
    &:hover {
      transform: translateX(2%);
    }
  }
  
  &--next {
    cursor: e-resize;
    
    &:hover {
      transform: translateX(-2%);
    }
  }
}

.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;

  opacity: 1;
  pointer-events: auto;
  user-select: auto;
  height: var(--slide-size);


  @media (hover: hover) {
    &:hover .slide__image-wrapper {
      transform: 
        scale(1.025)
        translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
    }    
  }
}

.slide__image-wrapper {
  background-color: var(--color-accent);
  border-radius: 1%;
  height: calc(85vh - var(--navbar-height));
  left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;  
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 100%;
}

.slide__image {
  --d: 20;
  max-width: 100vw;

  height: 110%;
  left: -5%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition:
    opacity var(--base-duration) var(--base-ease),
    transform var(--base-duration) var(--base-ease);
  user-select: none;
  width: 110%;

  @media (hover: hover) {
    .slide--current & {
      transform:
        translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
    }
  }
}

.slide__headline {
  font-size: 3rem; /* Increase font size */
  background-color: rgba(0, 0, 0, 0.5); /* Add semi-transparent background */
  color: #fff; /* Change text color to white */
  padding: 1rem; /* Add padding */
  margin: 0; /* Remove default margin */
  border-radius: 0.5rem; /* Add rounded corners */
  text-align: center; /* Center align text */
}

.slide__content {
  --d: 60;
  
  opacity: 0;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;
  
  .slide--current & {
    animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;
    
    @media (hover: hover) {
      transform: 
        translate(
          calc(var(--x) / var(--d) * -1px),
          calc(var(--y) / var(--d) * -1px)
        );
    }
  }
  
  > * + * {
    margin-top: 5vh;
  }
}


// =========================
// Animations
// =========================

@keyframes fade-in {
  from { opacity: 0 }
  to   { opacity: 1 }
}

// =========================
// Carousel
// =========================

.carousel-container {
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 5vh;
}




// small screens
@media (max-width: 768px) {
  .slide__headline {
    font-size: 2rem; /* Decrease font size */
  }

  .slider__controls .btn {
    --size: 3rem; /* Increase size */
    background-color: rgb(45, 55, 114);
    border-radius: 50%; /* Make the background circular */
  }


  .slider {
    --slide-width: 80vw;
    --slide-margin: 5vh;
    width: var(--slide-width);
  }

  // on mobile, make space for browser's controls at the bottom

  .slide__image-wrapper {
    height: calc(70vh - var(--navbar-height));
  }

  .slider__controls {
    top: calc(71vh - var(--navbar-height));
    }
}

