.svg-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
}

.svg-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.svg-item {
    margin: auto;
}

/* src/components/viewers/SvgViewer.css */
@media (max-width: 768px) {
    .svg-link {
        display: block;
        text-align: center;
        margin: 20px;
    }
}